@import "~bootstrap/scss/bootstrap";

.container {
    margin-top: 100px
}

html {
    height: 100%;
    body {
        height: 100%;
        background-size: cover;
    }

    #read-button-big {
        margin: 0 auto;
    }
}